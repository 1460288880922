const LoginProcedure = {
  checkCredentials: async function (url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });

    return response.json();
  },

  redirect: function (message, confirmed) {
    if (message === confirmed) {
      return true;
    } else {
      return false;
    }
  },
};

export default LoginProcedure;
