import React from "react";
import "../assets/styles/footer.scss";

export default function Footer() {
  return (
    <div id="footer_wrapper">
      <p>This Application was created by</p>
      <p>Chris Banks</p>
    </div>
  );
}
